@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

/* resets */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  overflow-x: hidden !important;
}

/* base styles */
#root {
  background-color: lightskyblue;
  display: flex;
  flex-direction: column;
  min-height: 100%; /* stops any content overflow */
}

main {
  flex: 1;
  display: flex; /* center everything */
  flex-direction: column; /* center everything */
  justify-content: center; /* center everything */
  align-items: center; /* center everything */
  overflow-x: hidden !important;
}

.weatherIcon {
  height: 60px;
  width: 60px;
}

.timeElement {
  font-family: 'Roboto', sans-serif;
  margin-top: 0 !important;
}

.tempElement {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}

.emergencyNumber {
  font-family: 'Roboto', sans-serif;
  text-decoration: none !important;
  transition: transform 0.3s ease-in-out;
}
.emergencyNumber:hover {
  transform: scale(1.1);
}

footer {
  text-align: center;
}
footer p {
  padding: 15px;
}
